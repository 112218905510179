require('./bootstrap');

let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});

function showModal(message) {
    $(".modal-body").html(message);
    $("#exampleModalCenter").modal("show");
}

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

function saveQuote(json) {
    $.ajax({
        type: "post",
        url: "/save-quote",
        data: json,
        success: function (response) {
            console.log("success");
        },
        error: function (error) {
            console.log("fail");
        }
    });
}

function initialize() {
    // const flatpickr = require("flatpickr");
    const callbackTime = flatpickr("#flatpicker", {
        enableTime: true,
        dateFormat: "F j, Y, H:i",
    });

    var input = document.getElementById('address');

    var options = {
        types: ['address']
    }

    if (input) {
        var autocomplete = new google.maps.places.Autocomplete(input, options);
        autocomplete.setComponentRestrictions({
            country: "CA"
        });
    }

    var beginApplicationButton = document.getElementById("beginApplicationButton");
    var firstSectionButton = document.getElementById("firstSectionButton");
    var secondSectionButton = document.getElementById("secondSectionButton");
    var firstSection = document.getElementById("firstSection");
    var secondSection = document.getElementById("secondSection");

    beginApplicationButton.onclick = function () {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    }

    firstSectionButton.onclick = function () {
        first_name = document.getElementById("first_name");
        last_name = document.getElementById("last_name");
        email = document.getElementById("email");
        phone = document.getElementById("phone");
        address = document.getElementById("address");

        var message = "<ul style='margin: 0px;'>";
        var errors = 0;

        if (first_name.value == "" ||
            last_name.value == "" ||
            email.value == "" ||
            phone.value == "" ||
            address.value == "") {
            message += "<li>Please make sure to fill out the entire form!</li>";
            errors++;
        } else {
            if (email.value == "") {
                message += "<li>Please fill out your email to continue!</li>";
                errors++;
            } else if (!email.value.includes("@")) {
                message += "<li>Please make sure to enter a valid email!</li>";
                errors++;
            }

            if (phone.value.length != 17 || phone.value.replace(/[^0-9.]/g, '').length != 11) {
                message += "<li>Please fill out your phone number to continue!</li>";
                errors++;
            }

            if (autocomplete.getPlace() == undefined) {
                if (!address.value.includes(", ON ") && !address.value.includes(", ON,")) {
                    message += "<li>You need to use Google suggestions to proceed!</li>";
                    errors++;
                }
            } else {
                var formatted_address = autocomplete.getPlace().formatted_address;
                address.value = formatted_address;
                if (address.value.includes(", ON,") || address.value.includes(", ON ")) {
                    address.value = formatted_address;
                } else {
                    message += "<li>Sorry, for now we only serve people in Ontario!</li>";
                    errors++;
                }
            }
        }

        if (errors > 0) {
            message += "</ul>";
            showModal(message);
        } else {
            firstSection.style.display = "none";
            secondSection.style.display = "block";
            saveQuote({
                "first_name": first_name.value,
                "last_name": last_name.value,
                "email": email.value,
                "phone": phone.value,
                "address": address.value,
            });
        }
    }

    secondSectionButton.onclick = function () {
        age = document.getElementById("age");
        employment_status = document.getElementById("employment_status");
        zero_down = document.getElementById("zero_down");
        company_name = document.getElementById("company_name");
        job_title = document.getElementById("job_title");
        income = document.getElementById("income");
        callback = document.getElementById('flatpicker')._flatpickr.latestSelectedDateObj;

        var message = "<ul style='margin: 0px;'>";
        var errors = 0;

        if (zero_down.value == "" ||
            employment_status.value == "" ||
            age.value == "" ||
            company_name.value == "" ||
            job_title.value == "" ||
            income.value == "")
        {
            message += "<li>Please make sure to fill out the entire form!</li>";
            errors++;
        }

        if (errors > 0) {
            message += "</ul>";
            showModal(message);
        } else {
            
            saveQuote({
                "zero_down": zero_down.value,
                "employment_status": employment_status.value,
                "age": age.value,
                "company_name": company_name.value,
                "job_title": job_title.value,
                "income": income.value,
                "callback_time": typeof callback == "undefined" ? null : callback.getTime() / 1000,
            });
            setTimeout(function () {
                var getUrl = window.location;
                var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[0];
                window.location.href = baseUrl + "thank-you";
            }, 1500);
        }
    }
}

google.maps.event.addDomListener(window, 'load', initialize);